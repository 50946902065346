<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 609 1280"><path d="M285 34c-5 12-15 57-19 82-9 65-17 197-22 387l-1 64 2 3c3 6 14 18 19 22l5 4 1 7c0 5 0 9 2 12 3 5 2 8-7 14-6 5-12 13-12 17l-2 3v5l1 6c0 3 5 10 12 18l6 7v17c0 20-1 23-10 29-8 6-16 9-25 7-9-1-17-5-22-9l-3-4-3 3-2 4c0 1-11 8-15 9-7 2-17 2-23 0s-11-5-15-10l-3-4-5 3c-3 3-3 3-9 1h-35c-18 4-36 14-48 26a89 89 0 00-24 55c-1 18 3 36 10 51 5 11 11 20 19 27 5 6 15 12 16 11l-1-7c-3-17 2-34 14-46 9-9 21-14 33-14 6 0 19 3 24 6l3 2 5-5c8-9 21-14 33-11 11 2 17 6 25 18l4-4c3-3 6-4 9-5l6-1c5 0 16 2 22 5 5 2 13 10 16 15 3 7 2 26-2 38-2 4-3 6-7 9-4 2-5 3-4 5l-1 3v11c-1 1 0 3 1 6 3 6 4 7 1 14-2 5-3 12-1 18 1 3 0 4-1 5-2 2-1 6 1 8l1 8-3 9v4l-1 4v3l2 20c0 7 0 8-2 10s-2 2-1 7c2 4 2 7 0 15s-3 16-1 24v6l-2 100v99l33-1 37-1h4v-45l1-93a1152 1152 0 010-64c1-2 1-17-1-22-2-6-2-14 0-18s2-6-1-6l-1-10 1-11c1 0 1-9-1-16l-1-13 1-10c2-1 2-4 1-7l-1-8c1-4 0-8-1-14l-2-9 2-5c2-5 3-7 2-10v-6l-1-4-1-4-3-2c-2 0-6-6-8-12l-1-17 1-15c2-4 16-11 23-12 10-2 22 0 31 4 13 7 26 22 28 33v4l5-1c9-1 15 2 22 9s9 13 9 22l-3 10-2 3 4 3 20 18c19 18 25 24 37 32 16 11 36 20 54 25 14 4 47 7 47 5l-13-13a272 272 0 01-92-174c-2-19-2-56 0-73 7-49 23-84 57-127a231 231 0 0142-41 169 169 0 00-73 10c-22 7-35 17-58 40-10 10-19 19-21 19l-4 2 2 5c5 9 3 22-4 30-6 5-11 7-19 7h-8l-2 6c-4 12-17 24-29 28-19 7-39 3-51-9l-4-4v-15c0-17 0-19 5-24l3-3 3-4c3-2 6-8 8-16 3-10 2-13-15-29-5-4-6-6-6-8l2-6c1-2 2-4 1-5l1-14 5-5c6-5 15-13 17-17 2-3 2-4 2-49-2-95-5-179-10-277a1143 1143 0 00-29-212c-1-3-5-3-6 0z"/></svg>
</template>

<script>

export default {
  name: 'test-icon',
}
</script>

<style>
svg {
  fill: currentColor
}
</style>

<template>
  <v-app>
    <v-main class="grey lighten-2">
      <v-container fluid>
        <link rel="preload" as="image" :href="require('./assets/logo.png')">
        <v-img
          alt="DSA Logo"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          max-height="150"
          class="mb-2"
        />
        <Quote/>
        <Calculator ref="calculator"/>
        <DiceThrow/>
        <UpdateSnackbar ref="updateSnackbar" v-on:updateRequested="updateRequested"/>
      </v-container>
      <custom-footer/>
    </v-main>
  </v-app>
</template>

<script>
import Calculator from '@/components/Calculator.vue';
import DiceThrow from './components/DiceThrow.vue';
import UpdateSnackbar from '@/components/UpdateSnackbar';
import CustomFooter from '@/components/CustomFooter';
import Quote from '@/components/Quote';

export default {
  name: 'App',

  components: {
    Quote,
    CustomFooter,
    UpdateSnackbar,
    DiceThrow,
    Calculator,
  },

  methods   : {
    updateRequested() {
      this.$refs.calculator.saveData();
    },
  }
};
</script>
<style>
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.v-input--reverse .v-input__slot .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}

.v-input--expand .v-input__slot {
  justify-content: space-between;
}

.v-input--expand .v-input__slot .v-label {
  display: block;
  flex: 1;
}
</style>

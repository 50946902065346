<template>
  <v-btn
    icon
    :color="this.color"
    :right="this.right"
    :absolute="this.absolute"
    @click="onClick"
  >
    <v-icon><slot></slot></v-icon>
  </v-btn>
</template>
<script>

export default {
  name   : 'icon-button',
  props  : {
    color: String,
    right: Boolean,
    absolute: Boolean
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
};
</script>

<template>
  <v-speed-dial bottom right fixed transition="slide-y-reverse-transition">
    <template v-slot:activator>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :color="color"
                 dark
                 fab
                 large
                 v-bind="attrs"
                 v-on="on"
                 aria-label="Einen Würfel werfen"
          >
            <v-icon>mdi-dice-multiple-outline</v-icon>
          </v-btn>
        </template>
        <span>Wirf einen Würfel</span>
      </v-tooltip>
    </template>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :color="color"
               fab
               dark
               @click.stop="rollDice(6)"
               v-bind="attrs"
               v-on="on"
               aria-label="Einen 6-seitigen Würfel werfen"
        >
          <v-icon>mdi-dice-d6</v-icon>
        </v-btn>
      </template>
      <span>6-seitiger Würfel</span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :color="color"
               fab
               dark
               @click.stop="rollDice(20)"
               v-bind="attrs"
               v-on="on"
               aria-label="Einen 20-seitigen Würfel werfen"
        >
          <v-icon>mdi-dice-d20</v-icon>
        </v-btn>
      </template>
      <span>20-seitiger Würfel</span>
    </v-tooltip>
    <v-btn color="green"
           fab
           dark
           small
           v-if="diceThrowResult"
           :loading="isDiceThrowLoading"
           aria-label="Ergebnis des Würfel-Wurfs"
    >
      {{ diceThrowResult }}
    </v-btn>
  </v-speed-dial>
</template>

<script>

export default {
  name   : 'DiceThrow',
  props  : {
    color: {
      type   : String,
      default: 'primary'
    },
  },
  data   : () => ({
    diceThrowResult   : 0,
    isDiceThrowLoading: false
  }),
  methods: {
    rollDice(maxValue) {
      this.isDiceThrowLoading = true;
      setTimeout(() => this.isDiceThrowLoading = false, 400);
      this.diceThrowResult = Math.floor(Math.random() * (maxValue)) + 1;
    },
  },
};
</script>

<template>
  <div class="plus-minus-input d-flex align-center flex-column">
    <v-chip
      v-if="label || labelIcon"
      :color="labelColor"
      out
      class="px-6 py-5 justify-center"
      style="width: 100%; max-width: 350px"
    >
      <v-icon :left="Boolean(label)" v-if="labelIcon" class="mr-2">
        {{ labelIcon }}
      </v-icon>
      {{label}}
    </v-chip>
    <div class="d-flex flex-row">
      <v-btn
        icon
        type="button"
        fab
        class="mx-2"
        :disabled="disabled || decrementDisabled || computedValue <= min"
        @click="decrement"
      >
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-text-field
        :name="name"
        ref="input"
        :value="computedValue"
        :placeholder="placeholder"
        :suffix="suffix"
        :max="max"
        :min="min"
        :autofocus="autofocus"
        :disabled="disabled"
        :readonly="readonly"
        @blur="onBlur"
        @change="onChange"
        @focus="onFocus"
        @input="onInput"
      />
      <v-btn
        icon
        type="button"
        fab
        class="mx-2"
        :disabled="disabled || incrementDisabled || computedValue >= max"
        @click="increment"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>

export default {
  name   : 'vue-numeric-input',
  props  : {
    name             : String,
    value            : Number,
    placeholder      : String,
    suffix           : String,
    min              : {
      type   : Number,
      default: 0,
    },
    max              : {
      type   : Number,
      default: 20,
    },
    step             : {
      type   : Number,
      default: 1,
    },
    align            : {
      type   : String,
      default: 'center',
    },
    autofocus        : {
      type   : Boolean,
      default: false,
    },
    readonly         : {
      type   : Boolean,
      default: false,
    },
    disabled         : {
      type   : Boolean,
      default: false,
    },
    decrementDisabled: {
      type   : Boolean,
      default: false,
    },
    incrementDisabled: {
      type   : Boolean,
      default: false,
    },
    label: {
      type   : String,
      default: undefined,
    },
    labelIcon: {
      type   : String,
      default: undefined,
    },
    labelColor: {
      type   : String,
      default: undefined,
    },
  },
  data() {
    return {
      valueModifier: null,
      typingTimer: null
    };
  },
  methods: {
    toNumber(val) {
      let num = parseFloat(val);
      if (Number.isNaN(val) || !Number.isFinite(val)) {
        num = 0;
      }
      return num;
    },
    /**
     * Increment the current numeric value
     */
    increment() {
      const newValue = this.toNumber(this.computedValue) + this.step;

      this.$emit('increment', this.updateValue(newValue));
    },
    /**
     * Decrement the current numeric value
     */
    decrement() {
      const newValue = this.toNumber(this.computedValue) - this.step;

      this.$emit('decrement', this.updateValue(newValue));
    },
    /**
     * Update value on operation performed
     * @param newValue
     */
    updateValue(newValue) {
      if (newValue >= this.max) {
        newValue = this.max;
      }
      if (newValue <= this.min) {
        newValue = this.min;
      }

      this.valueModifier = newValue - this.value

      this.$emit('change', newValue);

      return newValue;
    },
    /**
     * On blur event trigger
     * @param event - blur event on input
     */
    onBlur(event) {
      this.$emit('blur', event);
    },
    /**
     * On focus event trigger on input
     * @param event
     */
    onFocus(event) {
      this.$emit('focus', event);
    },
    /**
     * On change event trigger on input
     */
    onChange(newValue) {
      this.updateValue(Number.parseInt(newValue));
    },
    onInput(newValue) {
      clearTimeout(this.typingTimer);

      newValue = Number.parseInt(newValue);

      if (Number.isNaN(newValue)) {
        newValue = 0;
      }

      this.typingTimer = setTimeout(() => {
        this.updateValue(Number.parseInt(newValue));
      }, 300);
    },
    /**
     * focus method to set the focus on input
     */
    focus() {
      if (!this.disabled) {
        this.$refs.input.focus();
      }
    },
    /**
     * blur to be trigger on input
     */
    blur() {
      this.$refs.input.blur();
    },
  },
  computed: {
    computedValue() {
      return this.value + this.valueModifier;
    }
  },
  watch: {
    /**
     * If the value is changed from outside we have to reset the value modifier.
     * Otherwise weird things happen.
     *
     * @param newValue
     * @param oldValue
     */
    value(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      this.valueModifier = 0;
    }
  }
};
</script>

<style>
.plus-minus-input .v-text-field {
  min-width: 2em;
}

.plus-minus-input .v-text-field__slot > input {
  text-align: center;
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 1280 1141"><path d="M1255 26l-43 15c-149 50-310 112-427 163-127 55-222 106-275 146-13 10-17 15-19 20-5 19 14 53 53 96l9 11-10 3c-16 5-30 12-37 20a79 79 0 00-20 45c0 4-1 5-2 5-6 0-19 4-26 7-15 7-27 23-33 41-1 5-1 5 2 12l3 7-7 7A354364 354364 0 000 1012l20 12 20 11-5 23-4 23 22-4a1992 1992 0 0130-4 365 365 0 01-6 36l23-2 22-2-4 18-3 18 223-196 220-196 9 4 9 4 8-1c19-3 31-14 36-36l3-9 5 2c7 4 12 5 22 5 9 0 17-2 26-7 19-9 33-28 37-48l1-8 10 8c34 29 70 52 95 60 7 2 11 2 19 2 16 0 21-2 34-19 66-83 167-252 288-482 36-68 103-200 102-200l-7 2z"/></svg>
</template>

<script>

export default {
  name: 'spear',
}
</script>

<style>
svg {
  fill: currentColor
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-speed-dial',{attrs:{"bottom":"","right":"","fixed":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.color,"dark":"","fab":"","large":"","aria-label":"Einen Würfel werfen"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dice-multiple-outline")])],1)]}}])},[_c('span',[_vm._v("Wirf einen Würfel")])])]},proxy:true}])},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.color,"fab":"","dark":"","aria-label":"Einen 6-seitigen Würfel werfen"},on:{"click":function($event){$event.stopPropagation();return _vm.rollDice(6)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dice-d6")])],1)]}}])},[_c('span',[_vm._v("6-seitiger Würfel")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.color,"fab":"","dark":"","aria-label":"Einen 20-seitigen Würfel werfen"},on:{"click":function($event){$event.stopPropagation();return _vm.rollDice(20)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dice-d20")])],1)]}}])},[_c('span',[_vm._v("20-seitiger Würfel")])]),(_vm.diceThrowResult)?_c('v-btn',{attrs:{"color":"green","fab":"","dark":"","small":"","loading":_vm.isDiceThrowLoading,"aria-label":"Ergebnis des Würfel-Wurfs"}},[_vm._v(" "+_vm._s(_vm.diceThrowResult)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container class="calculator">
    <Intro ref="intro" v-on:hide="hasSeenIntro = true; saveData(false)"/>
    <v-divider/>
    <v-banner class="text-center mb-5 text--secondary">
      <h3>Berechnung der Charakter-Werte</h3>
    </v-banner>

    <icon-subheader icon="mdi-format-list-bulleted-type">
      Eigenschaften
      <icon-button
        @click="showInfos.eigenschaften = !showInfos.eigenschaften"
        color="blue"
        aria-label="Hilfe zu 'Eigenschaften' anzeigen"
      >
        mdi-help-circle-outline
      </icon-button>
    </icon-subheader>

    <info-banner v-if="this.showInfos.eigenschaften">
      Klappe die Karten auf und <b>trage</b> die Werte deiner <b>Charaktereigenschaften ein</b>. Dazu kannst du den Slider verschieben oder die Plus- und Minus-Knöpfe benutzen.<br>
      Mit deinen Eingaben werden dann deine <b>übrigen Charaktereigenschaften berechnet</b>.
    </info-banner>

    <v-row>
      <template v-for="(propertyType) in $data.properties">
        <v-col v-for="(property, propertyIndex) in propertyType"
               v-bind:key="propertyIndex"
               cols="12" sm="6" lg="4"
        >
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-badge
                  inline
                  class="justify-space-between"
                  :content="property.value.toString()"
                >
                  <div>
                    <v-icon class="mr-5"> {{property.icon}}</v-icon>
                    {{ property.name }}
                  </div>
                </v-badge>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <plus-minus-slider
                  :value="property.value"
                  v-on:change="property.value = $event"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </template>
    </v-row>

    <icon-subheader icon="mdi-format-list-numbered">
      Sonstige Werte
      <icon-button
        @click="showInfos.sonstigeWerte = !showInfos.sonstigeWerte"
        color="blue"
        aria-label="Hilfe zu 'Sonstige Werte' anzeigen"
      >
        mdi-help-circle-outline
      </icon-button>
    </icon-subheader>

    <info-banner v-if="showInfos.sonstigeWerte">
      Klappe die Karten auf und <b>trage</b> die Werte deiner <b>Attribute</b> (Lebensenergie und Abenteuerpunkte) und deiner <b>Rüstung ein</b>. Dazu kannst du die Plus- und Minus-Knöpfe benutzen oder den Wert direkt eintragen.<br>
      Mit deinen Eingaben werden dann deine übrigen <b>Charaktereigenschaften</b> sowie <b>Waffenwerte berechnet</b>.
    </info-banner>

    <v-row>
      <v-col cols="12" sm="6">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon class="mr-5">mdi-format-list-bulleted-type</v-icon>
                Attribute
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <v-row>
                <v-col cols="12" lg="6" v-for="(attribut, index) in $data.stats" v-bind:key="index">
                  <PlusMinusInput
                    :value="attribut.value"
                    v-on:change="attribut.value = $event"
                    :max="Number.POSITIVE_INFINITY"
                    :min="Number.NEGATIVE_INFINITY"
                    :label="attribut.name"
                    :label-icon="attribut.icon"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" sm="6">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon class="mr-5">mdi-shield</v-icon>
                Rüstung
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <v-row>
                <v-col cols="12" lg="6" v-for="(attribut, index) in $data.ruestung" v-bind:key="index">
                  <PlusMinusInput
                    :value="attribut.value"
                    v-on:change="attribut.value = $event"
                    :max="Number.POSITIVE_INFINITY"
                    :min="Number.NEGATIVE_INFINITY"
                    :label="attribut.name"
                    :label-icon="attribut.icon"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <icon-subheader icon="mdi-calculator-variant-outline">
      Berechnete Werte
      <icon-button
        @click="showInfos.berechneteWerte = !showInfos.berechneteWerte"
        color="blue"
        aria-label="Hilfe zu 'Berechnete Werte' anzeigen"
      >
        mdi-help-circle-outline
      </icon-button>
    </icon-subheader>

    <info-banner v-if="showInfos.berechneteWerte">
      In dieser Sektion werden in <b>Echtzeit</b> die <b>Attribute</b>, die <b>Stufe</b> und die <b>Kampf-Basiswerte</b> für deinen Charakter <b>berechnet</b>.<br>
      Um die konkreten Werte für die einzelnen Waffen zu berechnen, kannst du den Rechner weiter unten verwenden.
    </info-banner>

    <v-row>
      <v-col cols="12" sm="6">
        <v-expansion-panels :value="[0]" multiple><!-- :value="[0]" means expand first panel -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon class="mr-5">mdi-format-list-bulleted-type</v-icon>
                Berechnete Attribute
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                  <tr v-for="(stat, index) in calculations.stats" v-bind:key="index">
                    <td class="text-left">{{ stat.name }}</td>
                    <td class="text-right">
                  <span class="blue--text">
                    {{ stat.getValue() }}
                  </span>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" sm="6">
        <v-expansion-panels :value="[0]" multiple><!-- :value="[0]" means expand first panel -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div>
                <v-icon class="mr-5">mdi-sword-cross</v-icon>
                Berechnete Kampf-Basiswerte
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="(basiswert, index) in calculations.kampf.basiswert" v-bind:key="index">
                      <td class="text-left">{{ basiswert.name }}</td>
                      <td class="text-right">
                        <span class="blue--text">
                          {{ basiswert.getValue() }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-divider class="mt-10"/>
    <v-banner class="text-center mb-5 text--secondary">
      <h3>Berechnung der Waffen-Werte</h3>
    </v-banner>

    <!-- Show only if Kampf-Basiswerte seem to be too low (missing data) -->
    <info-banner
      v-if="calculations.kampf.basiswert.attacke.getValue()  <= 3
        || calculations.kampf.basiswert.parade.getValue()    <= 3
        || calculations.kampf.basiswert.fernkampf.getValue() <= 3"
    >
      Beachte, dass in die <b>Berechnung der Waffen-Werte</b> auch deine Charakter-Werte (Attacke-, Parade- & Fernkampf-Basiswert) mit einfließen.<br>
      Stelle sicher, dass du alle <b>notwendigen Charakter-Werte eingetragen</b> hast.
    </info-banner>

    <icon-subheader icon="mdi-fencing">
      Kampftechniken
      <icon-button
        @click="showInfos.waffenTaw = !showInfos.waffenTaw"
        color="blue"
        aria-label="Hilfe zu 'Kampftechniken' anzeigen"
      >
        mdi-help-circle-outline
      </icon-button>
    </icon-subheader>

    <info-banner v-if="showInfos.waffenTaw">
      Klappe die Karten auf und trage deine <b>Talentwerte der verschiedenen Kampftechniken</b> ein. Du musst nicht alle Werte eintragen, da sie nur genutzt werden um später die Attacke- & Parade-Werte deiner Waffen(talente) zu berechnen. Es reicht also wenn du <b>nur die für dich relevanten Kampftechniken</b> einträgst.<br>
      Zur Eingabe kannst du den Slider oder die Plus- und Minus-Knöpfe benutzen.
    </info-banner>

    <v-row>
      <template v-for="(kategorie) in kampf">
        <v-col
          v-for="(typ, kategorieIndex) in kategorie"
          v-bind:key="kategorieIndex"
          cols="12" sm="6" lg="4"
        >
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-badge
                  inline
                  class="justify-space-between"
                  :content="typ.value.toString()"
                >
                  <div>
                    <v-icon class="mr-5"> {{typ.icon}}</v-icon>
                    {{ typ.name }}
                  </div>
                </v-badge>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <plus-minus-slider
                  :value="typ.value"
                  v-on:change="typ.value = $event"
                  :max="18"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </template>
    </v-row>

    <icon-subheader icon="mdi-tune">
      Einstellungen AT- & PA-Berechnung
      <icon-button
        @click="showInfos.atPaCalcSettings = !showInfos.atPaCalcSettings"
        color="blue"
        aria-label="Hilfe zu 'Einstellungen AT- & PA-Berechnung' anzeigen"
      >
        mdi-help-circle-outline
      </icon-button>
    </icon-subheader>

    <info-banner v-if="showInfos.atPaCalcSettings">
      Hier kannst du <b>Einstellungen für die Attacke- und Parade-Berechnung</b> vornehmen.<br>
      Möchtest du deinen <b>allgemeinen Attacke- und Parade-Werte</b> für die einzelnen Waffenarten berechnen, dann deaktiviere, dass die Behinderung mit verrechnet wird.<br>
      Aktivierst du die Funktion, wird die Behinderung durch deine Rüstung mit eingerechnet und du kannst direkt die <b>AT- und PA-Werte für eine konkrete Waffe</b> bestimmen.<br>
      Beachte dabei die zweite Einstellung: Hat dein Charakter einen ungeraden Behinderungswert, kannst du entscheiden ob <b>AT oder PA stärker behindert</b> werden sollen.
      Bei einem geraden Behinderungswert werden AT und PA gleichmäßig belastet.
    </info-banner>

    <v-row>
      <v-col sm="6" cols="12">
        <v-card>
          <v-card-text class="px-6 pb-6">
            <v-radio-group v-model="calcAtPaWithBe" class="my-0" hide-details>
              <template v-slot:label>
                <label>Behinderung in AT- & PA-Berechnung berücksichtigen</label><br>
                <small>Bei Aktivierung können die finalen Waffenwerte abgelesen werden.</small>
              </template>
              <v-radio :value="true">
                <template v-slot:label>
                  <div>Ja</div>
                </template>
              </v-radio>
              <v-radio :value="false">
                <template v-slot:label>
                  <div>Nein</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col sm="6" cols="12">
        <v-card :disabled="!calcAtPaWithBe">
          <v-card-text class="px-6 pb-6">
            <v-radio-group v-model="oddBehinderungTarget" class="my-0" hide-details>
              <template v-slot:label>
                <label>Von Behinderung stärker betroffener Wert</label><br>
                <small>Soll AT oder PA bei einer ungeraden Behinderung stärker reduziert werden?</small>
              </template>
              <v-radio value="AT">
                <template v-slot:label>
                  <div>Attacke</div>
                </template>
              </v-radio>
              <v-radio value="PA">
                <template v-slot:label>
                  <div>Parade</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <icon-subheader icon="mdi-calculator">
      AT- & PA-Berechnung
      <icon-button
        @click="showInfos.atPaCalc = !showInfos.atPaCalc"
        color="blue"
        aria-label="Hilfe zu 'AT- & PA-Berechnung' anzeigen"
      >
        mdi-help-circle-outline
      </icon-button>
    </icon-subheader>

    <info-banner v-if="showInfos.atPaCalc">
      In dieser Sektion kannst du die <b>Attacke und Parade</b> für deine <b>verschiedenen Waffenarten</b> berechnen.<br>
      Für Fernkampftechniken gibt es keine Parade, daher können dort keine Punkte verteilt werden. Die finalen Werte werden dort automatisch berechnet.<br>
      Beachte hierbei die Einstellungen der vorigen Sektion.<br>
      Mit Hilfe der Plus- und Minus-Knöpfe kannst du die <b>verbleibenden Punkte auf Attacke und Parade verteilen</b>.<br>
      Wenn keine Punkte mehr übrig sind, kannst du die Werte ablesen und verwenden.
    </info-banner>

    <v-row>
      <template v-for="(kategorie, kampftechnik) in kampf">
        <v-col
          v-for="(typ, index) in kategorie"
          v-bind:key="index"
          cols="12" sm="6" lg="4"
        >
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <v-icon class="mr-5"> {{typ.icon}}</v-icon>
                  {{ typ.name }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <AtCalculator
                  v-if="kampftechnik === 'fern'"
                  :at-basis="calculations.kampf.basiswert.attacke.getValue()"
                  :ta-w="typ.value"
                  :Behinderung="calcBehinderungForKampftechnik(typ)"
                  flat
                />
                <AtPaCalculator
                  v-else
                  :at-basis="calculations.kampf.basiswert.attacke.getValue()"
                  :pa-basis="calculations.kampf.basiswert.parade.getValue()"
                  :ta-w="typ.value"
                  :Behinderung="calcBehinderungForKampftechnik(typ)"
                  :oddBehinderungTarget="oddBehinderungTarget"
                  flat
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </template>
    </v-row>

    <v-divider class="mt-9 mb-7"/>

    <icon-subheader icon="mdi-tools">
      Verwaltung
      <icon-button
        @click="showInfos.verwaltung = !showInfos.verwaltung"
        color="blue"
        aria-label="Hilfe zu 'Verwaltung' anzeigen"
      >
        mdi-help-circle-outline
      </icon-button>
    </icon-subheader>

    <info-banner v-if="showInfos.verwaltung">
      In dieser Sektion kannst du deine eingegebenen Daten verwalten.<br>
      Mit der Funktion <b>"Speichern"</b> werden deine Eingaben im Browser gespeichert. Praktischerweise werden deine Daten aber auch automatisch nach jeder Änderung gespeichert. Beachte, aber dass die Daten verschwinden, wenn du deinen Browser-Cache leerst!<br>
      Über <b>"Zurücksetzen"</b> setzt du alle Werte auf null zurück.<br>
      Durch einem Klick auf <b>"Daten herunterladen"</b> exportierst du deine Eingaben auf dein Gerät. So hast du eine Sicherung deiner Daten, auch wenn der Browser-Cache geleert wird.<br>
      Um deine exportierten Daten wiederherzustellen kannst du <b>"Daten importieren"</b> nutzen. Klicke auf den Knopf, wähle die exportierte Sicherungsdatei auf deinem Gerät aus und deine Daten werden wiederhergestellt.
    </info-banner>

    <v-row align="center" justify="space-around">
      <v-col md="6" cols="12" class="py-2">
        <v-btn @click="saveData" block large color="primary">
          <v-icon left>
            mdi-content-save
          </v-icon>
          Speichern
        </v-btn>
      </v-col>
      <v-col md="6" cols="12" class="py-2">
      <v-dialog
        v-model="showResetDialog"
        persistent
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn block large
                 color="secondary lighten-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>
              mdi-backup-restore
            </v-icon>
            Zurücksetzen
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline">
            Werte zurücksetzen?
          </v-card-title>
          <v-card-text>
            Sollen wirklich alle Werte auf null zurückgesetzt werden?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary lighten-1"
              text
              @click="resetData(); showResetDialog = false"
            >
              Ja
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="showResetDialog = false"
            >
              Nein
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-col>
      <v-col md="6" cols="12" class="py-2">
        <v-btn @click="downloadData" block large>
          <v-icon left>
            mdi-download
          </v-icon>
          Daten herunterladen
        </v-btn>
      </v-col>
      <v-col md="6" cols="12" class="py-2">
        <file-input-button
          color=""
          accept="application/json"
          @change="uploadData($event.target.files[0])">
          <v-icon left>
            mdi-upload
          </v-icon>
          Daten importieren
        </file-input-button>
      </v-col>
    </v-row>

    <v-snackbar v-model="showSnackbar">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="accent"
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>

import AtPaCalculator from '@/components/AtPaCalculator.vue';
import PlusMinusInput from '@/components/PlusMinusInput.vue';
import PlusMinusSlider from '@/components/PlusMinusSlider.vue';
import IconSubheader from '@/components/IconSubheader';
import FileInputButton from '@/components/FileInputButton';
import IconButton from '@/components/IconButton';
import InfoBanner from '@/components/InfoBanner';
import Intro from '@/components/Intro';
import AtCalculator from '@/components/AtCalculator';

export default {
  name      : 'Calculator',
  components: {
    AtCalculator,
    InfoBanner,
    IconButton,
    FileInputButton,
    IconSubheader,
    AtPaCalculator,
    PlusMinusInput,
    PlusMinusSlider,
    Intro
  },
  data      : () => ({
    properties          : {
      positive: {
        Mut             : {
          name : 'Mut',
          value: 0,
          icon: 'mdi-medal-outline'
        },
        Klugheit        : {
          name : 'Klugheit',
          value: 0,
          icon: 'mdi-brain'
        },
        Intuition       : {
          name : 'Intuition',
          value: 0,
          icon: 'mdi-lightbulb-on-outline'
        },
        Charisma        : {
          name : 'Charisma',
          value: 0,
          icon: 'mdi-face-shimmer'
        },
        Fingerfertigkeit: {
          name : 'Fingerfertigkeit',
          value: 0,
          icon: 'mdi-gesture-double-tap'
        },
        Gewandtheit     : {
          name : 'Gewandtheit',
          value: 0,
          icon: 'mdi-karate'
        },
        Koerperkraft    : {
          name : 'Körperkraft',
          value: 0,
          icon: 'mdi-arm-flex-outline'
        },
      },
      negative: {
        Aberglaube: {
          name : 'Aberglaube',
          value: 0,
          icon: 'mdi-ghost-outline'
        },
      },
    },
    stats               : {
      Lebensenergie  : {
        name : 'Lebensenergie',
        value: 0,
        icon : 'mdi-bottle-tonic-plus-outline',
      },
      Abenteuerpunkte: {
        name : 'Abenteuerpunkte',
        value: 0,
        icon : 'mdi-star-four-points-outline',
      },
    },
    kampf               : {
      unbewaffnet: {
        raufen: {
          name       : 'Raufen',
          value      : 0,
          behinderung: 0,
          icon       : 'mdi-kabaddi'
        },
      },
      bewaffnet  : {
        aexte      : {
          name       : 'Äxte & Beile',
          value      : 0,
          behinderung: -3,
          icon       : 'mdi-axe'
        },
        messer     : {
          name       : 'Messer & Dolche',
          value      : 0,
          behinderung: -1,
          icon       : 'mdi-knife-military'
        },
        scharfeHieb: {
          name       : 'Scharfe Hiebwaffen',
          value      : 0,
          behinderung: -2,
          icon       : '$vuetify.icons.halberd'
        },
        schwerter  : {
          name       : 'Schwerter',
          value      : 0,
          behinderung: -2,
          icon       : 'mdi-sword'
        },
        speere     : {
          name       : 'Speere & Stäbe',
          value      : 0,
          behinderung: -2,
          icon       : '$vuetify.icons.spear'
        },
        stichwaffen: {
          name       : 'Stichwaffen',
          value      : 0,
          behinderung: -1,
          icon       : 'mdi-knife'
        },
        stumpfeHieb: {
          name       : 'Stumpfe Hiebwaffen',
          value      : 0,
          behinderung: -3,
          icon       : 'mdi-baseball-bat'
        },
        zweihaender: {
          name       : 'Zweihänder',
          value      : 0,
          behinderung: -2,
          icon       : 'mdi-sword'
        },
      },
      fern       : {
        schuss: {
          name : 'Schusswaffen',
          value: 0,
          icon : 'mdi-bullseye-arrow'
        },
        wurf  : {
          name : 'Wurfwaffen',
          value: 0,
          icon : '$vuetify.icons.throwing-star'
        },
      }
    },
    ruestung            : {
      ruestschutz: {
        name : 'Rüstschutz',
        value: 0,
        icon : 'mdi-shield',
      },
      behinderung: {
        name : 'Behinderung',
        value: 0,
        icon : 'mdi-wheelchair-accessibility',
      },
      gewicht    : {
        name : 'Gewicht',
        value: 0,
        icon : 'mdi-weight'
      },
    },
    calcAtPaWithBe      : false,
    showResetDialog     : false,
    showSnackbar        : false,
    hasSeenIntro        : false,
    snackbarText        : '',
    oddBehinderungTarget: 'AT',
    saveTimeoutTimer    : null,
    showInfos: {
      eigenschaften: false,
      sonstigeWerte: false,
      waffenTaw: false,
      berechneteWerte: false,
      atPaCalcSettings: false,
      atPaCalc: false,
      verwaltung: false
    }
  }),
  methods   : {
    autoSave() {
      clearTimeout(this.saveTimeoutTimer);

      this.saveTimeoutTimer = setTimeout(() => {
        this.saveData(false);
      }, 3000);
    },
    saveData(showSnackbar = true) {
      localStorage.setItem('data', JSON.stringify(this.$data));

      if (showSnackbar) {
        this.snackbarText = 'Werte erfolgreich gespeichert.'
        this.showSnackbar = true;
      }
    },
    resetData() {
      function resetRecursive(object) {
        for (let property in object) {
            if (property === 'value') {
              object[property] = 0;
            }

            if (typeof object[property] === 'object') {
              resetRecursive(object[property]);
            }
        }
      }

      resetRecursive(this.$data);

      this.calcAtPaWithBe = false;
      this.oddBehinderungTarget = 'AT';
    },
    downloadData() {
      const data = JSON.stringify(this.$data)
      const Blob = new window.Blob([data], {type: 'application/json'})

      let Link = document.createElement('a')
      Link.href = window.URL.createObjectURL(Blob)
      Link.download = 'data.json'

      document.body.appendChild(Link);
      Link.click()
      document.body.removeChild(Link);
    },
    uploadData(File) {
      if (!File) {
        return;
      }

      File.text()
        .then((text) => {
          let data = false;
          try {
            data = JSON.parse(text);
          } catch (Exception) {
            data = false;
          }

          if (!data) {
            alert('Datei konnte nicht geparsed werden, breche ab.');
            return;
          }

          this.importData(data);
        });
    },
    importData(data) {
      this.resetData();

      data.showSnackbar = false;

      Object.assign(this.$data, data);
    },
    log(content) {
      console.log(content);
    },
    calcBehinderungForKampftechnik(kampftechnik, force = false) {
      if (!force && (!this.calcAtPaWithBe || typeof kampftechnik.behinderung === 'undefined')) {
        return 0;
      }

      return this.ruestung.behinderung.value + kampftechnik.behinderung;
    },
  },
  computed  : {
    calculations() {
      const self = this;

      return {
        stats: {
          Ausdauer: {
            name: 'Ausdauer',
            getValue() {
              return self.stats.Lebensenergie.value + self.properties.positive.Koerperkraft.value;
            },
          },
          MR      : {
            name: 'Magieresistenz',
            getValue() {
              let sum = self.properties.positive.Mut.value;
              sum += self.properties.positive.Klugheit.value;
              sum += self.calculations.stats.Stufe.getValue();

              return Math.floor(sum / 3) - (self.properties.negative.Aberglaube.value * 2);
            },
          },
          Stufe   : {
            name: 'Stufe',
            getValue() {
              let abenteuerpunkte = self.stats.Abenteuerpunkte.value;
              let stufe = 0;

              while (abenteuerpunkte >= 0) {
                stufe += 1;
                abenteuerpunkte -= stufe * 100;
              }

              return stufe;
            },
          },
        },
        kampf: {
          basiswert: {
            attacke   : {
              name: 'Attacke Basiswert',
              getValue() {
                const sum = self.properties.positive.Mut.value
                            + self.properties.positive.Gewandtheit.value
                            + self.properties.positive.Koerperkraft.value;

                return Math.round(sum / 5);
              },
            },
            parade    : {
              name: 'Parade Basiswert',
              getValue() {
                const sum = self.properties.positive.Intuition.value
                            + self.properties.positive.Gewandtheit.value
                            + self.properties.positive.Koerperkraft.value;

                return Math.round(sum / 5);
              },
            },
            fernkampf : {
              name: 'Fernkampf Basiswert',
              getValue() {
                const sum = self.properties.positive.Intuition.value
                            + self.properties.positive.Fingerfertigkeit.value
                            + self.properties.positive.Koerperkraft.value;

                return Math.round(sum / 4);
              },
            },
            ausweichen: {
              name: 'Ausweichen Basiswert',
              getValue() {
                const sum = self.properties.positive.Mut.value
                            + self.properties.positive.Intuition.value
                            + self.properties.positive.Gewandtheit.value;

                return Math.round(sum / 4);
              },
            },
          },
        },
      };
    },
  },
  mounted() {
    let data = localStorage.getItem('data');

    if (data) {
      data = JSON.parse(data);

      this.importData(data);
    }

    if (!this.hasSeenIntro) {
      this.$refs.intro.show();
    }
  },
  watch: {
    properties    : {
      handler() {
        this.autoSave();
      },
      deep: true
    },
    stats         : {
      handler() {
        this.autoSave();
      },
      deep: true
    },
    kampf         : {
      handler() {
        this.autoSave();
      },
      deep: true
    },
    ruestung      : {
      handler() {
        this.autoSave();
      },
      deep: true
    },
    calcAtPaWithBe: {
      handler() {
        this.autoSave();
      },
      deep: true
    },
  },
};
</script>

<style scoped>
.row {
  margin-bottom: 1em;
}

.v-subheader {
  padding: 0;
}
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 1280 1280"><path d="M534 165c-1 147-1 147-5 177s-13 61-24 83a156 156 0 01-35 43l-9 4c-10 5-19 7-31 10l-81 2c-79 1-73 0-81 7l-5 4A9268 9268 0 000 745l140 1c146 1 146 1 176 5 90 13 142 53 151 115 2 9 2 23 2 82v70l124 131 126 131 1-14c0-8 0-14 1-13l3 1c3 1 6-1 6-5l1-130 2-142a294 294 0 0134-122l7-8c16-17 34-28 58-33 9-2 12-2 85-3h75l8-7a5300 5300 0 00253-245c1 0 2-4 1-6l-4-3-131-1c-133-1-136-1-168-6-85-12-136-48-151-103-3-16-4-30-4-96l-1-66-3-7L572 40c-13-13-16-16-19-15-2 1-4 7-4 11v3l-8-8-7-7v141zm112 414c27 7 48 28 55 56 2 7 2 10 2 21-1 14-3 24-10 35-4 7-14 18-15 18l-6 2c-11 5-19 6-32 6-14 0-22-1-34-7a78 78 0 01-37-102l2-6a68 68 0 0155-25l20 2z"/></svg>
</template>

<script>

export default {
  name: 'throwing-star',
}
</script>

<style>
svg {
  fill: currentColor
}
</style>

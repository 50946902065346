<template>
  <v-btn
    :color="color"
    :loading="loading"
    @click="onButtonClick"
    block
    large
  >
    <slot/>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      :accept="accept"
      @change="onFileChanged"
    >
  </v-btn>
</template>

<script>

export default {
  name   : 'FileInputButton',
  props  : {
    accept: {
      type   : String,
      default: '*'
    },
    color : {
      type   : String,
      default: 'primary'
    },
    icon  : {
      type   : String,
      default: 'mdi-upload'
    },
    loading: {
      type   : Boolean,
      default: false
    },
    text  : String
  },
  data   : () => ({
    selectedFile: null,
  }),
  methods: {
    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(event) {
      this.$emit('change', event);
    }
  }
};
</script>

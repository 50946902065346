<template>
  <v-snackbar :value="isUpdateAvailable" :timeout="-1">
    Update verfügbar
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        :loading="isRefreshing"
        color="accent"
        v-bind="attrs"
        @click="refreshApp"
      >
        Aktualisieren
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'UpdateSnackbar',
  data: () => ({
    isRefreshing     : false,
    registration     : null,
    isUpdateAvailable: false,
  }),
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });

    if (navigator.serviceWorker) {
      // Prevent multiple refreshes
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.isRefreshing) {
          return;
        }

        this.isRefreshing = true;

        window.location.reload();
      });
    }
  },

  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail;
      this.isUpdateAvailable = true;
    },

    // Called when the user accepts the update
    refreshApp() {
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) {
        return;
      }

      this.$emit('updateRequested');

      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
  },
};
</script>

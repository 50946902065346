<template>
  <v-card class="at-calculator d-flex flex-column justify-space-between align-center"
          :flat="flat"
  >
    <v-card-title v-if="name">{{ name }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <PlusMinusInput
            :decrement-disabled="true"
            :increment-disabled="true"
            :value="ResultingAt"
            :readonly="true"
            label="Attacke"
            label-icon="mdi-sword"
            class="mb-5"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-subtitle class="text-center">
      Da es hier keine Parade gibt, müssen keine Punkte verteilt werden. Der Wert wurde automatisch berechnet.
    </v-card-subtitle>
  </v-card>
</template>

<script>

import PlusMinusInput from '@/components/PlusMinusInput.vue';

export default {
  name      : 'AtCalculator',
  components: { PlusMinusInput },
  props     : {
    name       : String,
    AtBasis    : Number,
    TaW        : Number,
    Behinderung: {
      type   : Number,
      default: 0,
    },
    flat       : {
      type   : Boolean,
      default: false,
    },
  },
  computed  : {
    ResultingAt() {
      const result = this.AtBasis + this.TaW - this.Behinderung;

      this.$emit('at-change', result);

      return result;
    },
  },
};
</script>

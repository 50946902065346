<template>
  <v-subheader>
    <v-icon class="mr-2">
      {{ icon }}
    </v-icon>
    <slot></slot>
  </v-subheader>
</template>

<script>

export default {
  name   : 'IconSubheader',
  props  : {
    text: String,
    icon: String
  }
};
</script>

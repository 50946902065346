<template>
  <v-alert
    type="info"
    text
    border="left"
    v-bind="{...$props, ...$attrs}"
    ref="alert"
    transition="expand-transition"
  >
    <slot></slot>
  </v-alert>
</template>
<script>

export default {
  name   : 'info-banner',
  methods: {
    toggle() {
      return this.$refs.alert.toggle();
    }
  }
};
</script>

<template>
  <v-card class="at-pa-calculator d-flex flex-column justify-space-between align-center"
          :flat="flat"
  >
    <v-badge
      v-if="showBadge && PointsLeft"
      overlap
      class="align-self-end"
      style="position: absolute"
      :content="PointsLeft"
    />
    <v-card-title v-if="name">{{name}}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col xl="6" cols="12">
      <PlusMinusInput
        v-on:decrement="At -= 1"
        v-on:increment="At += 1"
        :decrement-disabled="At <= 0"
        :increment-disabled="PointsLeft <= 0"
        :value="ResultingAt"
        :readonly="true"
        label="Attacke"
        label-icon="mdi-sword"
        class="mb-5"
      />
        </v-col>
        <v-col xl="6" cols="12">
      <PlusMinusInput
        v-on:decrement="Pa -= 1"
        v-on:increment="Pa += 1"
        :decrement-disabled="Pa <= 0"
        :increment-disabled="PointsLeft <= 0"
        :value="ResultingPa"
        :readonly="true"
        label="Parade"
        label-icon="mdi-shield"
      />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-subtitle>
      Verbleibende Punkte: <span v-bind:class="{'blue--text': PointsLeft}">{{PointsLeft}}</span>
    </v-card-subtitle>
  </v-card>
</template>

<script>

import PlusMinusInput from '@/components/PlusMinusInput.vue';

export default {
  name: 'AtPaCalculator',
  components: { PlusMinusInput },
  props: {
    name: String,
    AtBasis: Number,
    PaBasis: Number,
    TaW: Number,
    showBadge: {
      type: Boolean,
      default: false
    },
    Behinderung: {
      type: Number,
      default: 0,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    // Welcher Wert wird stärker reduziert bei ungerader Behinderung?
    oddBehinderungTarget: {
      type: String,
      validator(value) {
        return ['AT', 'PA'].includes(value);
      },
      default: 'AT',
    },
  },
  data: () => ({
    At: 0,
    Pa: 0,
  }),
  methods: {
    log(content) {
      console.log(content);
    },
    calcBehinderungForCategory(category) {
      let round = Math.floor;

      if (category === this.oddBehinderungTarget) {
        round = Math.ceil;
      }

      return round(this.Behinderung / 2);
    },
  },
  computed: {
    PointsLeft() {
      return this.TaW - this.At - this.Pa;
    },
    ResultingAt() {
      const AT = this.AtBasis + this.At;
      const BE = this.calcBehinderungForCategory('AT');

      const result = AT - BE;

      this.$emit('at-change', result);

      return result;
    },
    ResultingPa() {
      const PA = this.PaBasis + this.Pa;
      const BE = this.calcBehinderungForCategory('PA');

      const result = PA - BE;

      this.$emit('pa-change', result);

      return result;
    },
  },
};
</script>

import '@mdi/font/css/materialdesignicons.css';
import 'typeface-roboto/index.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Halberd from '@/components/icons/Halberd';
import Spear from '@/components/icons/Spear';
import ThrowingStar from '@/components/icons/ThrowingStar';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      'throwing-star': {
        component: ThrowingStar
      },
      'spear': {
        component: Spear
      },
      halberd: {
        component: Halberd
      },
    }
  }
});

<template>
  <div class="dsa-quote text-center grey--text text--darken-1">
    <div>
      <blockquote class="v-size--large">„{{ randomQuote.text }}“</blockquote>
      <i class="text-caption">— {{ randomQuote.author }} —</i>
    </div>
    <icon-button
      @click="generateRandomIndex"
      color="grey"
      aria-label="Neues Zitat anzeigen"
    >
      mdi-reload
    </icon-button>
  </div>
</template>

<script>
import IconButton from '@/components/IconButton';

export default {
  name      : 'Quote',
  components: { IconButton },
  data      : () => ({
    index : 0,
    quotes: [
      {
        text  : 'Je weniger die Leute davon wissen, wie Würste und Gesetze gemacht werden, desto besser schlafen sie.',
        author: 'Abelmir von Marvinko, Staatsminister des Horasreichs'
      },

      {
        text  : 'Arm ist nicht der, der wenig hat, sondern der, der nicht genug bekommen kann.',
        author: 'Meister Achmed ibn Mhukkadin al Ghunar'
      },

      {
        text  : 'Unser Ziel ist das Geheimnis eines Geheimnisses, das durch ein Geheimnis verschlossen bleibt.',
        author: 'ein hoher Mawdli'
      },

      {
        text  : 'Seht nur, wie die Kriege und Kämpfe zu nichts führen, denn die Toten auf der anderen Seite müssen mit den Toten in den eigenen Reihen bezahlt werden.',
        author: 'aus den Lehren des alten Suilem'
      },

      {
        text  : 'Jeder Freund ist zu Anfang ein Fremder.',
        author: 'Mutter Traviane von Lowangen'
      },

      {
        text  : 'Länder, die durch Ungerechtigkeiten groß werden, bleiben nicht lange groß.',
        author: 'Alttulamidisches Sprichwort'
      },

      {
        text  : 'Die Götter schützen nicht nach Art der Hirten, indem sie den Stab ergreifen. Wen die Götter zu schützen gewillt sind, den bedenken sie mit Einsicht.',
        author: 'ein reisender Hesindegeweihter'
      },

      {
        text  : 'Menschen sind wie Edelsteine: Man lernt sie am besten kennen, indem man sie aus der Fassung bringt.',
        author: 'Orax, Sohn des Egrom'
      },

      {
        text  : 'Am Ende steht immer der Stahl.',
        author: 'Geofax in einer Vision'
      },

      {
        text  : 'Erst in der Wüste erkennst du, wie Wasser wirklich schmeckt!',
        author: 'Khiran al eb Sahilar, novadischer Händler'
      },

      {
        text  : 'Die beste Tarnung ist die Wahrheit. Die glaubt einem keiner!',
        author: 'Drego von Angenbruch, KGIA-Agent'
      },

      {
        text  : 'Des Lebens größter Sinn ist: ihn zu suchen. Des Lebens größter Unsinn ist: zu glauben ihn gefunden zu haben.',
        author: 'Avesgeweihter auf Reisen'
      },

      {
        text  : 'Auch aus Steinen, die einem in den Weg gelegt werden, kann man ein schönes Muster legen.',
        author: 'Shanhazadra, Geweihte der Rahja, ehemals Geliebte der Göttin'
      },

      {
        text  : 'Auf drei Säulen ruht Aventurien: Wahrheit, Glaube und Gerechtigkeit!',
        author: 'Praiodan Greif, Inqusitor'
      },

      {
        text  : 'Wie der Adler, der gleich nach dem Schlüpfen den schwächeren Bruder erwürgt und aus dem Nest schleudert, kann auch der Grande nur zu Größe gelangen, der seine Anverwandten als die schlimmsten Feinde behandelt.',
        author: 'Goldo Paligan, genannt der Großartige'
      },

      {
        text  : 'Wissen wohnt in Köpfen voll der Gedanken anderer, Weisheit im Herzen, das den eigenen lauscht.',
        author: 'Großmeister Erilarion Androstaal'
      },

      {
        text  : 'Auch wenn die Wahrheit manchmal niedersinkt, verliert sie nie ihr Leben!',
        author: 'Greifwin von Werdenk, Geweihter des Praios und Inquisitor'
      },

      {
        text  : 'Liebe ist ein Glas, das zerbricht, wenn man es zu fest oder zu zaghaft in den Händen hält.',
        author: 'Talafeyar, Halbelf und Hochgeweihter des Perricumer Rahjatempels'
      },

      {
        text  : 'Es mag schwer sein, den Weg der Göttin zu beschreiten, aber sinnlos ist dies niemals!',
        author: 'Ayla von Schattengrund, Schwert der Schwerter'
      },

      {
        text  : 'Der nichts weiß, hofft!',
        author: 'unbekannt'
      },

      {
        text  : 'Die Faulheit ist der Humus des Geistes.',
        author: 'Spektabilität Khadil Okharim'
      },

      {
        text  : 'Für alles im Leben muss man bezahlen. Je später man es tut, desto höher sind die Zinsen.',
        author: 'Amir Honak, Patriarch von Al\'Anfa'
      },

      {
        text  : 'Drei Dinge gibt es, die man nicht wieder zurückholen kann: den Pfeil, der vom Bogen schnellte, das unbedacht gesprochene Wort und die verpaßte Gelegenheit.',
        author: 'Siras Sarosil, Geweihter des Firun, Vertreter im Zwölfgöttlichen Konzil zu Perainefurten'
      },

      {
        text  : 'Und steht der Zwerg erst auf eines Riesen Schultern frei, prahlt er, dass er größer als der Riese sei.',
        author: 'Salandrion Farnion Finkenfarn'
      },

      {
        text  : 'Was soll das heißen, der Name des Meisters wird mir den Weg weisen? Ich habe keinen Meister über mir!',
        author: 'Abu Terfas, eine Woche vor seinem gewaltsamen Tod durch Borbarad'
      },

      {
        text  : 'Wissen ist ein Trugbild, da alles, was wir als Wissen bezeichnen, von der Zeit und von unserer Umgebung abhängig ist.',
        author: 'Adeptus Alix Zander, Schule des Wandelbaren zu Tuzak'
      },

      {
        text  : 'Vergeßt niemals, daß Swafnirs Fürsorge so grenzenlos ist, wie sein Zorn zerstörend.',
        author: 'Jurge Swafnirsgrehd, Erwählter des Swafnir zu Prem'
      },

      {
        text  : 'Nichts in der Welt ist zuverlässiger als der Glanz des Goldes und die Heimtücke der Elfen.',
        author: 'Altes Zwergensprichwort'
      },

      {
        text  : 'Treffen Einfalt und Gründlichkeit zusammen, entsteht Verwaltung.',
        author: 'unbekannt'
      },

      {
        text  : 'Stets muss man, um Erfolg zu haben, närrisch scheinen und weise sein.',
        author: 'Prinz Storko von Gareth'
      },

      {
        text  : 'Die Einsicht in das Mögliche und Unmögliche ist es, die den Helden vom Abenteurer scheidet.',
        author: 'Raidri Conchobair'
      },

      {
        text  : 'Popularität ist nur dazu da, verbraucht zu werden.',
        author: 'Oderin du Metuant, Triumvirator'
      },

      {
        text  : 'Die beste Methode Geld zu machen, ist Geld zu machen.',
        author: 'Alte Falschmünzerweisheit'
      },

      {
        text  : 'Ein prächtiger Erfolg für euch, Herrschaften. Der Wurm zertreten, der Drache unbesiegt – oder denkt ihr, ihr habt je anders als nach meinem Textbuch gespielt?',
        author: 'Borbarad, zu den Gezeichneten'
      },

      {
        text  : 'Wenn ein Mensch nicht tanzen kann, dann sagt er: ‚Die Musiker können nicht spielen.’ Es liegt an uns, dass die Welt so ist, wie sie ist.',
        author: 'Yasinthe von Tuzak, ehemalige Geliebte der Göttin'
      },

      {
        text  : 'Groß nenne ich die Männer und Frauen, die sich in der Magie hervorgetan haben. Die anderen, die Provinzen verheeren, sind bloß Helden.',
        author: 'Convocatus Primus Saldor Foslarin'
      },

      {
        text  : 'Freunde in Deiner Begleitung sind mehr wert als das beste Schwert an Deiner Seite.',
        author: 'Der Wanderer, Geweihter des Aves'
      },

      {
        text  : 'Am leichtesten kann man Meinungsverschiedenheiten zwischen Leuten ausgleichen, die keine eigene Meinung haben.',
        author: 'Meisterin Sheddja'
      },

      {
        text  : 'Geld verdirbt den Charakter - vor allem, wenn man keins hat.',
        author: 'Graf Rabosch, Sohn des Reshmin von Waldwacht'
      },

      {
        text  : 'Wir leben alle unter demselben Himmel, aber wir haben nicht alle denselben Horizont.',
        author: 'Großmeister Erilarion Androstaal'
      },

      {
        text  : 'Wer der Herrin Tsa nicht entgangen ist, entgeht auch nicht dem Herrn Boron.',
        author: 'Mutter Rowinja, Geweihte des Raben'
      },

      {
        text  : 'Wer über gewisse Dinge nicht der Verstand verliert, der hat keinen zu verlieren.',
        author: 'Erzmagus Rakorium Muntagonus'
      },

      {
        text  : 'Wer das Wort der Götter hört, den hören die Götter auch.',
        author: 'Zwölfgöttlicher Sinnspruch'
      },

      {
        text  : 'Die gefährlichste Weltanschauung ist die Weltanschauung der Leute, welche die Welt nie angeschaut haben.',
        author: 'Großmeister Erilarion Androstaal'
      },

      {
        text  : 'Angrosch hat die Zwerge nach seinem Ebenbild geschaffen, aber die Zwerge haben es ihm wahrlich heimgezahlt.',
        author: 'Xenos von der Flamme'
      },

      {
        text  : 'Die Weise guter Zecher ist zu früher und später Stunde, dass alter Wein im Becher ist, und neuer Sang im Munde.',
        author: 'Vierzeiler seiner Majestät Kaiser Valpo'
      },

      {
        text  : 'Die inquisitio ist ein unverzichtbarer Teil der Wahrheitsfindung.',
        author: 'Baron Dexter Nemrod'
      },

      {
        text  : 'Die Gebote der Herrin Hesinde sind deswegen so kurz und logisch, weil sie ohne Mitwirkung von Juristen zustandegekommen sind.',
        author: 'Aldare Firdayon, Kronprinzessin des Horasreichs'
      },

      {
        text  : 'Wer das Schöne nicht sieht, wird böse. Wer das Schlechte nicht sieht, wird dumm.',
        author: 'Ineyja Vendalat, maraskanische Assassinin'
      },

      {
        text  : 'Die Neugier steht immer an erster Stelle eines Problems, das gelöst werden will.',
        author: 'Großmeister Erilarion Androstaal'
      },

      {
        text  : 'Man hat einen Menschen noch lange nicht bekehrt, wenn man ihn zum Schweigen gebracht hat.',
        author: 'Reichsgroßgeheimrat Dexter Nemrod'
      },

      {
        text  : 'Verlass dich nie auf die Waffe, die du siehst.',
        author: 'Aigolf Thuranson, der Seebär aus dem Bornland'
      },

      {
        text  : 'Macht an sich bedeutet mir nichts. Es ist lediglich das Wörtchen \'Macht\', das die Menschen in Aufruhr versetzt.',
        author: 'Dexter C. O. von Bahrenstein, Gastredner auf dem Heptarchentreffen in Elburum, Efferd 1022'
      },

      {
        text  : 'Nichts auf der Welt ist so gerecht verteilt wie der Verstand: Jeder glaubt, er habe genug davon.',
        author: 'Hochwürden Argelia von Kuslik, Geweihte der Hesinde an der Academia Limbologica'
      },

      {
        text  : 'Viele Menschen sind zu gut erzogen, um mit vollem Mund zu sprechen, aber sie haben keine Scheu, es mit leerem Kopf zu tun.',
        author: 'Rallion Regenflieder, Elf'
      },

      {
        text  : 'Das Leben und der Tod sind zwei Geheimnisse, verschlossen hinter zwei Türen, von denen jede den Schlüssel zur anderen verbirgt.',
        author: 'Rohal der Weise'
      },

      {
        text  : 'Die Palmen haben den Kopf gern im Feuer und die Füße im Wasser.',
        author: 'Novadische Redensart'
      },

      {
        text  : 'Wer loslässt, hat beide Hände frei, etwas Neues anzufangen.',
        author: 'Tsaiade von Sennstein, Geweihte der Eidechse'
      },

      {
        text  : 'Ehe bedeutet gegenseitige Freiheitsberaubung in beiderseitigem Einvernehmen.',
        author: 'Rahjageweihte, die ungenannt bleiben möchte'
      },

      {
        text  : 'Macht macht nicht glücklich - aber sie entschädigt dafür, nicht glücklich zu sein.',
        author: 'Salix Kugres'
      },

      {
        text  : 'Mit dem Horasreich und der Bildung ist das so eine Sache: Jeder Horasier ist stolz auf die Fortschritte der Gesellschaft, doch kein Horasier macht Fortschritte.',
        author: 'adptus maior Eboreus Zachariad'
      },

      {
        text  : 'Du bist gnädig zu mir und ich danke dir für das, was du mir zufügst.',
        author: 'al\'anfanischer Sklave zu seinem Herren'
      },

      {
        text  : 'Erkläre mir, und ich werde vergessen. Zeige mir, und ich werde mich erinnern. Beteilige mich, und ich werde verstehen.',
        author: 'Erzmagus Thomeg Atherion (in jungen Jahren)'
      },

      {
        text  : 'Wer seine Bitte nur weiß zitternd vorzutragen, lehrt den, der bittet, ihm sein Bitten abzuschlagen.',
        author: 'Meister Eborëus Zachariad'
      },

      {
        text  : 'Früher hielten sich viele Könige einen Narren. Heute halten sich viele Narren einen König.',
        author: 'Schelm am Kaiserhof in Gareth (zumindest bis zum Zeitpunkt dieser Aussage)'
      },

      {
        text  : 'Mit leerem Kopf nickt es sich leichter.',
        author: 'unbekannter Geweihter des Nandus'
      },

      {
        text  : 'Jedes Leben entsteht zu seiner Zeit, wird eine Zeitlang bewahrt und zum Ende seiner Zeit wieder genommen.',
        author: 'Quelina vom Salmfang, Meisterin des Flusses zu Albenhus'
      },

      {
        text  : 'Nichts, was füreinander bestimmt ist, bleibt auf ewig getrennt.',
        author: 'ein Schelm aus Garetien bei Betrachtung eines kandierten Apfels'
      },

      {
        text  : 'Nur wenn Erz durch Kraft und Verstand auf Feuer und Wasser trifft, wird das Schwert zwischen Hammer und Amboß geschmiedet, wie es einem Meisterschmied geziemt.',
        author: 'Bewahrer der Kraft, seine Erhabenheit Xolgorim, Sohn des Xaraf'
      },

      {
        text  : 'Für den Landmann, sei er Bauer oder Knecht, gibt\'s in der Welt nicht Gold noch Recht!',
        author: 'aus dem Lied \'Der Ritter und die Magd\''
      },

      {
        text  : 'Nur ein Traum, sagt ihr?',
        author: 'Seine Hochwürdigste Erhabenheit Amir Honak'
      },

      {
        text  : 'Aufrichtigkeit ist die wahrscheinlich verwegenste Form der Tapferkeit.',
        author: 'Dexter Nemrod in einer unbedachten Stunde'
      },

      {
        text  : 'Unzählige vor mir haben Völker und Städte beherrscht, aber ganz wenige nur sich selbst.',
        author: 'Borbarad'
      },

      {
        text  : 'Glaubt mir, Angst und Furcht sind völlig irrationale Verhaltensweisen. Sie verhindern das Unausweichliche nicht!',
        author: 'Archon Megalon, ehemaliger Hofdruide des Fürsten zu Havena'
      },

      {
        text  : 'Erfahrene Juristen bezeugen, dass es vor Gericht durchaus von Vorteil sein kann, wenn man im Recht ist.',
        author: 'Abelmir von Marvinko, Staatsminister des Horasreichs'
      },

      {
        text  : 'Zukunft ist wie ein Land unter dem Nebel der Nacht im Lichte des Madamals.',
        author: 'Luzelin vom Blauen Wald'
      },

      {
        text  : 'Aber, aber, schönste Dame, warum verhängt ein Schleier Liebfrauen Rahjas gunstvolle Gaben? Seid ihr Bange, sie könnten staubig werden?',
        author: 'Brandil v. Ehrenstein ä.H. und Streitzig, Graf von Ragath'
      },

      {
        text  : 'Die beste Informationsquelle sind Leute, die versprochen haben, nichts weiterzuerzählen.',
        author: 'Dexter Nemrod, Reichgroßgeheimrat'
      },

      {
        text  : 'Magier sind Menschen, die sich von normalen Sterblichen durch die anerworbene Fähigkeit unterscheiden, sich an weitschweifigen und komplizierten Irrtümern zu ergötzen.',
        author: 'Arombolosch, Sohn des Agam'
      },

      {
        text  : 'Nur weil ihr nicht unter Verfolgungswahn leidet, bedeutet dies nicht, dass ihr nicht verfolgt werdet.',
        author: 'Rakorium, Erzmagus'
      },

      {
        text  : 'Man sollte dieses Leben nicht allzu ernst nehmen. Hier ist sowieso noch keiner lebend rausgekommen.',
        author: 'Rahjadan Bonareth'
      },

      {
        text  : 'Tadle die Götter nicht, dass sie den Ork erschaffen haben - danke den Zwölfen dafür, dass sie ihm keine Flügel gaben!',
        author: 'Bornländisches Sprichwort'
      },

      {
        text  : 'Es gibt solche und solche \'alte Bekannte\'...',
        author: 'Silana von Ask, Mondschattin des Phex'
      },

      {
        text  : 'Der Weg zum Ruhm führt durch die Paläste, der zum Gold durch die Basare, der zum Glück durch die Wüste.',
        author: 'novadisches Sprichwort'
      },

      {
        text  : 'Eine schmerzliche Wahrheit ist besser als eine Lüge.',
        author: 'Hilberian Praiofold III. Lumerian, der Bote des Lichts'
      },

      {
        text  : 'Die Leidenschaft für die Wahrheit wird zum Schweigen gebracht durch Antworten, die das Gewicht unbestrittener Autorität haben.',
        author: 'Meisterin Sheddja'
      },

      {
        text  : 'Es ist unmöglich, die Fackel der Wahrheit durch ein Gedränge zu tragen, ohne jemanden den Bart zu versengen.',
        author: 'Pagol Greifax von Gratenfels, Geweihter des Praios, nach einem Missionierungsversuch bei den Zwergen'
      },

      {
        text  : 'Denn was bedeuten Schritte und Meilen schon für den Göttersohn?!',
        author: 'Borbarad'
      },

      {
        text  : 'Erkenntnis wird in erster Linie aus Schmerz geboren.',
        author: 'Archon Megalon'
      },

      {
        text  : 'Das Geheimnis des Erfolges ist, dass es kein Geheimnis gibt.',
        author: 'Nareb Emano Zornbrecht'
      },

      {
        text  : 'Wer in die Fußstapfen eines großen Mannes tritt, hinterläßt keine eigenen!',
        author: 'Meister Elcarna Erillion, Magister zu Lowangen'
      },

      {
        text  : 'Ich bin bereit, den Preis der Macht zu zahlen, was immer und wie hoch er auch sein mag!',
        author: 'Tar Honak'
      },

      {
        text  : 'Der Kampf um Dere wird nicht in Alveran gewonnen, sondern auf dem Boden Aventuriens!',
        author: 'Der Greif, Herold des PRAios'
      },

      {
        text  : 'Der Erfolg bietet sich meist denen, die kühn handeln; nicht denen, die alles wägen und nichts wagen wollen.',
        author: 'Reichserzmarschall Leomar vom Berg'
      },

      {
        text  : 'Wie lange bist du schon in Al’Anfa? Ein Jahr? Nun, dann gehörst du ja schon zu den Ureinwohnern.',
        author: 'Kalauer der Alanfanischen Fremdenlegion'
      },

      {
        text  : 'Es ist nicht der Dorn, der dich verletzt. Du selbst verletzt dich am Dorn.',
        author: 'Olirion Sternenblick, Waldelf'
      },

      {
        text  : 'Vertraut mir, ich weiß was gut für euch ist.',
        author: 'Irschan Perval'
      },

      {
        text  : 'Sagt ein Magier zum Anderen: \'Heißt es jetzt eigentlich Amaze-ROTH, Ama-ZE-roth oder A-MA-zeroth?\' - Sagt eine Stimme: \'Egal, wenn du es nur dreimal hintereinander sagst!\'',
        author: 'Magierwitz aus Brabak'
      },

      {
        text  : 'Es stört mich nicht, was meine Minister sagen, - solange sie tun, was ich ihnen sage.',
        author: 'aus: Kaisersprüche von Amene Horas III.'
      },

      {
        text  : 'Das beste Mittel, um getäuscht zu werden, ist sich für schlauer zu halten als die anderen.',
        author: 'Großmeister Erilarion Androstaal'
      },

      {
        text  : 'Bedenket also, dass ihr nur Narren seid im unendlichen Kreis der Welt – und dass das Wissen, das uns nicht zu verstehen ist, auch nicht von uns verstanden werden kann.',
        author: 'Meister Barius von Charypso'
      },

      {
        text  : 'Manche Weintrauben leben als Rosinen weiter - im Kopfe eines Narren.',
        author: 'aus: Kaisersprüche von Kaiser Valpo dem Zecher'
      },

      {
        text  : 'Wer glaubt, ein praiosgefälliger Mensch zu sein, nur weil er den Tempel besucht, irrt sich. Man wird auch nicht zum Pferd, wenn man in einen Stall geht.',
        author: 'Dexter Nemrod, Reichgroßgeheimrat'
      },

      {
        text  : 'Von jedem Ort Deres aus führt ein Pfad zur Verdammnis!',
        author: 'Illuminos Sickerbach, Geweihter des Praios'
      },

      {
        text  : 'In der Ehe kämpft der Mann zuerst um seine Vorherrschaft, dann um seine Gleichberechtigung und schließlich um seine Duldung.',
        author: 'aranisches Sprichwort'
      },

      {
        text  : 'Du kannst das Leben eines geliebten Menschen nicht verlängern, wohl aber bereichern.',
        author: 'Aillil Andara Galahan, Geweihte der Rahja, Geliebte der Göttin'
      },

      {
        text  : 'Wenn der Ehrgeiz als Zwerg zur Welt kommt, nennt man ihn Eitelkeit.',
        author: 'unbekannt'
      },

      {
        text  : 'Man muss sich entscheiden können: Entweder Arbeiten oder Geldverdienen.',
        author: 'Wulf Sturmfels, Geweihter des Fuchses'
      },

      {
        text  : 'Sparsamkeit ist die Lieblingsregel aller halblebendigen Menschen.',
        author: 'Söldner, unbekannt'
      },

      {
        text  : 'Wissen und Weisheit bedingen sich nicht gegenseitig, mein Freund.',
        author: 'Argelion, Hoher Lehrmeister der Hesinde'
      },

      {
        text  : 'Das Herz des Menschen ist wie der Boden des Meeres - unergründlich.',
        author: 'Araine Efferdstreu, Geweihte des Delfins'
      },

      {
        text  : 'Erkläre mir, und ich werde vergessen.',
        author: 'unbekannt'
      },

      {
        text  : 'Die Wahrheit ist alles andere als eine erhabene und gestrenge Herrin; sie ist eine gefügige und gehorsame Dienerin.',
        author: 'Dirial von Zornbrecht-Lomarion'
      },

      {
        text  : 'Es gibt Geschenke, die zu machen man sich nicht leisten kann und es gibt Geschenke, die nicht zu machen man sich nicht leisten kann.',
        author: 'Wulf Sturmfels, Geweihter des Fuchses'
      },

      {
        text  : 'Der mehr Macht hat, hat mehr Recht!',
        author: 'Torstor Jorason, Magister'
      },

      {
        text  : 'Nahema? O ja. Sie ist sehr schön, solange du nicht den Fehler begehst, zu lange und zu tief in ihre Augen zu blicken.',
        author: 'Aigolf'
      },

      {
        text  : 'Drei Dinge lassen sich nur bei drei Gelegenheiten erkennen: Die Kühnheit in der Gefahr, die Vernunft im Zorn und die Freundschaft in der Not.',
        author: 'Spektabilität Saldor Foslarin, Convocatus Primus der Weißen Gilde'
      },

      {
        text  : 'Mit Maßen trink den Rebensaft und du gewinnst dir seine Kraft. Doch gibst du dich dem Unmaß hin, verlierst du deine Kraft an ihn!',
        author: 'Troubardour Oschero v. Drosselstreif'
      },

      {
        text  : 'Wer glaubt, dass ein Schelm nur das Lachen kennt, kennt das Lachen nicht.',
        author: 'Flarach, kaiserlicher Hofnarr zu Gareth'
      },

      {
        text  : 'Vertraue auf Stein und Eisen - Stein und Eisen waren schon immer die einzig wahren Freunde der Zwerge.',
        author: 'Altes Zwergensprichwort'
      },

      {
        text  : 'Bei den Zwergen ist es so wie bei den Menschen: Mit der Zeit vollbringen ihre Vorfahren immer ruhmreichere Taten.',
        author: 'Salandrion Farnion Finkenfarn'
      },

      {
        text  : 'Schweigen bedeutet bei einem großen Teil für einen großen Teil der Menschheit Gewinn.',
        author: 'Borondria, Großmeisterin der Golgariten'
      },

      {
        text  : 'Es gibt Wesen, die verstehen keinen Spaß. Es gibt vielerlei Möglichkeiten, ihnen zu begegnen. Die meisten davon verstehen sie ebenso wenig.',
        author: 'Koboldischer Lehrsatz'
      },

      {
        text  : 'Das Problem ist, dass man die Frau für\'s Leben nicht nur einmal trifft.',
        author: 'Morlan Dranbo, liebfeldischer Herzensbrecher'
      },

      {
        text  : 'Nie hat es einen großen Geist ohne Beimischung von Wahnsinn gegeben.',
        author: 'Archon Megalon über Borbarad'
      },

      {
        text  : 'Klug ist ein jeder, der eine vorher, der andere nachher.',
        author: 'Wulf Sturmfels, Geweihter des Fuchses'
      },

      {
        text  : 'Die Trennung vom Leibe gilt dem Wissenden nicht als Tod.',
        author: 'Rhazzazor'
      },

      {
        text  : 'Klugheit erwirbt man durch Narben.',
        author: 'Meister Verdan von Leuenstein, Lehrmeister der Kampfkunst in Vinsalt'
      },

      {
        text  : 'Mitleid ist kostenlos - Neid muss man sich erst verdienen.',
        author: 'Goldo Paligan, Markgraf von Hot-Alem'
      },

      {
        text  : 'Es bedeutet fürwahr einen Unterschied, sein Schicksal nur zu empfangen oder es zu kennen.',
        author: 'Dratchur Meredin Hazar'
      },

      {
        text  : 'Man kann auf einem Standpunkt stehen, aber man sollte nicht darauf sitzen.',
        author: 'adeptus Eborëus Zachariad'
      }
    ]
  }),
  mounted() {
    this.generateRandomIndex();
  },
  computed: {
    randomQuote() {
      return this.quotes[this.index];
    }
  },
  methods : {
    generateRandomIndex() {
      this.index = Math.floor(Math.random() * this.quotes.length);
    }
  }
};
</script>
<style scoped>
.dsa-quote {
  max-width: 500px;
  margin: 0 auto;
}

blockquote {
  font-family: serif;

}
</style>

<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="isOpen"
    v-on:click:outside="hide"
    max-width="1000"
  >
    <v-stepper v-model="currentStep" style="box-shadow: none; border: none; border-radius: 0;">
      <v-stepper-header>
        <v-stepper-step :complete="currentStep > 0" step="0"/>
        <v-divider/>
        <v-stepper-step :complete="currentStep > 1" step="1"/>
        <v-divider/>
        <v-stepper-step :complete="currentStep > 2" step="2"/>
        <v-divider/>
        <v-stepper-step :complete="currentStep > 3" step="3"/>
        <v-divider/>
        <v-stepper-step :complete="currentStep > 3" step="4"/>
      </v-stepper-header>

      <v-stepper-items class="text-center grey lighten-4">
        <v-stepper-content class="fill-height" step="0" style="min-height: 326px">
          <div class="d-flex flex-column align-center justify-center">
            <h2 class="mb-5 blue--text">Willkommen, Abenteurer!</h2>
            <p>Um deine Orientierung zu verbessern, folgt eine kurze Einführung zur Verwendung dieses Rechners.</p>
            <video src="@/assets/intro/calculator.mp4" autoplay muted loop/>
          </div>
        </v-stepper-content>

        <v-stepper-content step="1">
          <div class="d-flex flex-column align-center justify-center">
            <h2 class="mb-5 blue--text">Karten aufklappen</h2>
            <v-img src="../assets/intro/open-card.gif"
                   alt="'Karte aufklappen'-Animation"
                   class="mb-5"
            />
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="d-flex flex-column align-center justify-center">
            <h2 class="mb-5 blue--text">Werte eintragen</h2>
            <v-img src="../assets/intro/enter-values.gif"
                   alt="'Werte eintragen'-Animation"
                   class="mb-5"
            />
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <div class="d-flex flex-column align-center justify-center">
            <h2 class="mb-5 blue--text">Hilfe aufrufen</h2>
            <v-img src="../assets/intro/help.gif"
                   alt="'Hilfe finden'-Animation"
                   class="mb-5"
            />
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <div class="d-flex flex-column align-center justify-center">

            <h2 class="mb-5 blue--text">Werte ablesen</h2>
            <v-img src="../assets/intro/calculated-values.gif"
                   alt="'Werte ablesen'-Animation"
                   class="mb-5"
            />
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div class="white d-flex align-center justify-center" style="height: 72px;">
      <v-btn v-if="this.currentStep < 4" color="primary" @click="currentStep++">
        Weiter
      </v-btn>

      <v-btn v-if="this.currentStep === 4" color="primary" @click="hide">
        Los geht's
      </v-btn>

      <v-btn text @click="hide" v-if="this.currentStep < 4">
        Überspringen
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>

export default {
  name   : 'Intro',
  data   : () => ({
    currentStep: 0,
    isOpen     : false
  }),
  methods: {
    show() {
      if (this.hiddenByProp) {
        return;
      }

      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
      this.$emit('hide');
    }
  },
};
</script>

<template>
  <v-slider
    append-icon="mdi-plus"
    prepend-icon="mdi-minus"
    @click:append="increment"
    @click:prepend="decrement"
    :vertical="vertical"
    :thumb-label="thumbLabel"
    :thumb-size="42"
    :min="min"
    :max="max"
    :value="computedValue"
    :disabled="disabled"
    :readonly="readonly"
    :step="step"
    @end="onEnd"
    @blur="onBlur"
    @change="onChange"
    @focus="onFocus"
  ></v-slider>
</template>
<script>

export default {
  name    : 'plus-minus-slider',
  props   : {
    vertical: {
      type   : Boolean,
      default: false,
    },
    value   : {
      type   : Number,
      default: 0,
    },
    min     : {
      type   : Number,
      default: 0,
    },
    max     : {
      type   : Number,
      default: 20,
    },
    step    : {
      type   : Number,
      default: 1,
    },
    readonly: {
      type   : Boolean,
      default: false,
    },
    disabled: {
      type   : Boolean,
      default: false,
    },
    label: {
      type: String,
      default: ''
    },
    thumbLabel: {
      default: true
    }
  },
  data() {
    return {
      valueModifier: null
    };
  },
  methods : {
    toNumber(val) {
      let num = parseFloat(val);
      if (Number.isNaN(val) || !Number.isFinite(val)) {
        num = 0;
      }
      return num;
    },
    /**
     * Increment the current numeric value
     */
    increment() {
      const newValue = this.toNumber(this.computedValue) + this.step;

      this.$emit('increment', this.updateValue(newValue));
    },
    /**
     * Decrement the current numeric value
     */
    decrement() {
      const newValue = this.toNumber(this.computedValue) - this.step;

      this.$emit('decrement', this.updateValue(newValue));
    },
    /**
     * Update value on operation performed
     * @param newValue
     */
    updateValue(newValue) {
      if (newValue >= this.max) {
        newValue = this.max;
      }
      if (newValue <= this.min) {
        newValue = this.min;
      }

      this.valueModifier = newValue - this.value;

      this.$emit('change', newValue);

      return newValue;
    },
    /**
     * On blur event trigger
     * @param event - blur event on input
     */
    onBlur(event) {
      this.$emit('blur', event);
    },
    /**
     * On focus event trigger on input
     * @param event
     */
    onFocus(event) {
      this.$emit('focus', event);
    },
    onEnd(event) {
      this.updateValue(event);
    },
    /**
     * On change event trigger on input
     */
    onChange() {
      this.$emit('change', this.computedValue);
    },
  },
  computed: {
    computedValue() {
      return this.value + this.valueModifier;
    }
  },
  watch   : {
    /**
     * If the value is changed from outside we have to reset the value modifier.
     * Otherwise weird things happen.
     *
     * @param newValue
     * @param oldValue
     */
    value(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }

      this.valueModifier = 0;
    }
  }
};
</script>
<style>
  /**
  Enlarges slider thumb touch target
  Source: https://github.com/vuetifyjs/vuetify/issues/4356#issuecomment-727628661
  */
  .v-slider__thumb:after {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    content: '';
    color: inherit;
    width: 400%;
    height: 400%;
    border-radius: 50%;
    background: transparent;
    position: absolute;
    left: -150%;
    top: -150%;
  }
</style>
